<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.676" height="12.278" viewBox="0 0 14.676 12.278">
    <g id="Grupo_980" data-name="Grupo 980" transform="translate(-40.953 -469.779)">
      <path id="Caminho_118" data-name="Caminho 118" d="M10.8,18.676V17.45A2.45,2.45,0,0,0,8.351,15H3.45A2.45,2.45,0,0,0,1,17.45v1.225" transform="translate(40.553 462.78)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      <circle id="Elipse_38" data-name="Elipse 38" cx="2.304" cy="2.304" r="2.304" transform="translate(44.625 470.43)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      <path id="Caminho_119" data-name="Caminho 119" d="M21.838,18.726V17.5A2.45,2.45,0,0,0,20,15.13" transform="translate(33.191 462.73)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      <path id="Caminho_120" data-name="Caminho 120" d="M16,3.13a2.45,2.45,0,0,1,0,4.748" transform="translate(34.742 467.379)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: "User"
}
</script>
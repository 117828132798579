<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.396" height="15.396" viewBox="0 0 19.3 19.5">
    <g id="Group_223" data-name="Group 223" transform="translate(-2.35 -2.25)">
      <g id="Group_295" data-name="Group 295" transform="translate(3.1 3)">
        <path id="Vector" d="M4.734,0A2.685,2.685,0,0,1,7.418,2.687V4.739A2.685,2.685,0,0,1,4.734,7.426H2.684A2.685,2.685,0,0,1,0,4.739V2.687A2.685,2.685,0,0,1,2.684,0Z" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke-dasharray="0 0"/>
        <path id="Vector-2" data-name="Vector" d="M4.734,0A2.685,2.685,0,0,1,7.418,2.687V4.739A2.685,2.685,0,0,1,4.734,7.426H2.684A2.685,2.685,0,0,1,0,4.739V2.687A2.685,2.685,0,0,1,2.684,0Z" transform="translate(0 10.564)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke-dasharray="0 0"/>
        <path id="Vector-3" data-name="Vector" d="M4.734,0A2.685,2.685,0,0,1,7.418,2.687V15.313A2.685,2.685,0,0,1,4.734,18H2.684A2.685,2.685,0,0,1,0,15.313V2.687A2.685,2.685,0,0,1,2.684,0Z" transform="translate(10.382)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke-dasharray="0 0"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "Panel"
}
</script>
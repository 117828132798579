<template>
    <v-dialog
        :value="dialog"
        @input="$emit('update:dialog', false)"
        max-width="500"
        persistent
    >
      <v-card class="pa-4">
        <v-card-title class="text-h5">
          {{ user.password_reset ? 'Você redefiniu sua senha recentemente.' : 'Redefinição de Senha' }}
          <v-spacer></v-spacer>
          <v-btn
              v-if="!user.password_reset"
              @click="closeDialog()"
              color="error"
              text
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <custom-h2 :label="user.password_reset ? 'Nosso protocolo de segurança exige que seja definida uma nova senha para prosseguir com o uso do sistema.' : ''"/>
          <v-row class="mt-4">
            <v-col cols="12">
              <span class="font-weight-bold">Senha Atual</span>
              <simple-text-field v-model="payload.old_password" password required/>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-bold">Nova Senha</span>
              <simple-text-field v-model="payload.new_password" password required/>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-bold">Confirmação de Senha</span>
              <simple-text-field v-model="payload.password_confirm" password required/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <primary-button @callAction="save()" label="Salvar"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import SimpleTextField from "../../../components/inputs/SimpleTextField";
import CustomH2 from "../../texts/CustomH2";
import PrimaryButton from "../../buttons/PrimaryButton";

export default {
  name: "PasswordChangeDialog",
  components: {CustomH2, PrimaryButton, SimpleTextField},
  props: {
    dialog: Boolean,
    user: Object
  },
  data() {
    return {
    payload: {}
    }
  },
  methods: {
    closeDialog() {
      this.payload = {};
      this.$emit('closeDialog')
    },

    save() {
      if(this.payload.new_password != this.payload.password_confirm) {
        this.$toasted.show('A nova senha não coincide com a confirmação de senha!', {
          type: "error",
        });
        return false;
      }

      let loader = this.$loading.show();
      this.payload.user_id = this.user.id;

      LoginService.changePassowrd(this.payload)
          .then(() => {
            loader.hide();
            this.$toasted.show('Senha alterada com sucesso.', {
              type: "success",
            });
            this.closeDialog();
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
    }
  }
}
</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.505" height="15.913" viewBox="0 0 17.505 15.913">
    <g id="pocket" transform="translate(8.752 7.957) rotate(180)">
      <g id="pocket-2" data-name="pocket" transform="translate(-7.957 -7.161)">
        <path id="Caminho_386" data-name="Caminho 386" d="M3.591,3H16.322a1.591,1.591,0,0,1,1.591,1.591V9.365A7.957,7.957,0,1,1,2,9.365V4.591A1.591,1.591,0,0,1,3.591,3Z" transform="translate(-2 -3)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.591"/>
        <path id="Caminho_387" data-name="Caminho 387" d="M8,10l3.183,3.183L14.365,10" transform="translate(-3.226 -4.43)" fill="none" stroke="#212020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.591"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "Ranking"
}
</script>
<template>
  <v-navigation-drawer
      width="290"
      v-model="drawerStatus"
      app
      floating
      class="shadow-nav"
  >
    <v-sheet class="pa-8">
      <v-row align="center" justify="center">
        <app-logo></app-logo>
      </v-row>
    </v-sheet>

      <v-row align="center" justify="center" class="px-4" v-if="authority.slug === 'producer'">
          <avatar class="menu-avatar pa-4 my-4 font-weight-bold flex full-width"
                  :initials="initials"
                  :username="username"
                  :dark="true"
          />
      </v-row>

    <nav-menu :authority="authority.name ? authority.name : ''"
              :permissions="permissions">
    </nav-menu>

  </v-navigation-drawer>
</template>

<script>
import AppLogo from "@/components/navbar/AppLogo";
import NavMenu from "@/components/navbar/NavMenu";
import Avatar from "@/components/navbar/Avatar";

export default {
  name: "Navbar",
  components: {
    AppLogo,
    NavMenu,
    Avatar
  },
  props: [
    'drawer',
    'authority',
    'permissions',
    'initials',
    'username'
  ],
  data() {
    return {
      activeDrawer: false,
      authorityService: null,
    }
  },
  computed: {
    drawerStatus: {
      get: function () {
        return this.drawer;
      },
      set: function (v) {
        this.$emit('update:drawer', v)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shadow-nav {
  box-shadow: 0 0 30px rgba(0, 0, 0, .05);
  border-right: 1px solid $secondaryUiColor;
}
</style>
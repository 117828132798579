<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.396" height="15.396" viewBox="0 0 15.396 15.396">
    <path id="Caminho_122" data-name="Caminho 122" d="M16.686,3H4.711A1.716,1.716,0,0,0,3,4.711V16.686A1.716,1.716,0,0,0,4.711,18.4H16.686A1.716,1.716,0,0,0,18.4,16.686V4.711A1.716,1.716,0,0,0,16.686,3Zm0,1.711V7.277H4.711V4.711Zm0,4.277v3.421H4.711V8.988Zm-11.975,7.7V14.12H16.686v2.566Z" transform="translate(-3 -3)" fill="#212020"/>
  </svg>

</template>

<script>
export default {
  name: "Register"
}
</script>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="13.843" height="29.96" viewBox="0 0 13.843 29.96">
    <path id="Caminho_268" data-name="Caminho 268" d="M41.627,15.677a4.44,4.44,0,0,0-.492.6V14.853c.624-.567,2.458-2.427,2.458-4.718,0-2.7-2.555-4.81-2.664-4.9a.557.557,0,0,0-.7,0c-.111.088-2.664,2.2-2.664,4.9,0,2.291,1.834,4.151,2.459,4.718v1.424a4.441,4.441,0,0,0-.493-.6,6.752,6.752,0,0,0-4.687-1.608c-.407,0-.661.027-.661.027a.557.557,0,0,0-.5.5c-.014.14-.33,3.438,1.58,5.347a6.75,6.75,0,0,0,4.685,1.607h.072v3.136a4.441,4.441,0,0,0-.493-.6A6.752,6.752,0,0,0,34.84,22.48c-.407,0-.661.027-.661.027a.557.557,0,0,0-.5.5c-.014.14-.33,3.438,1.58,5.347a6.75,6.75,0,0,0,4.685,1.607h.072v4.557a.557.557,0,1,0,1.114,0V29.954h.072a6.751,6.751,0,0,0,4.685-1.607C47.8,26.437,47.484,23.139,47.47,23a.557.557,0,0,0-.5-.5s-.253-.027-.661-.027a6.755,6.755,0,0,0-4.687,1.608,4.439,4.439,0,0,0-.492.6V21.545h.072a6.751,6.751,0,0,0,4.685-1.607c1.909-1.909,1.594-5.207,1.58-5.347a.557.557,0,0,0-.5-.5s-.253-.027-.661-.027A6.772,6.772,0,0,0,41.627,15.677Zm-2.952-5.544a5.785,5.785,0,0,1,1.9-3.711,5.764,5.764,0,0,1,1.9,3.711,5.768,5.768,0,0,1-1.9,3.71,5.792,5.792,0,0,1-1.9-3.71Zm-2.624,9.02a5.8,5.8,0,0,1-1.281-3.969h.069a5.805,5.805,0,0,1,3.9,1.281,5.8,5.8,0,0,1,1.281,3.968A5.763,5.763,0,0,1,36.051,19.153Zm0,8.407a5.8,5.8,0,0,1-1.281-3.969h.069a5.805,5.805,0,0,1,3.9,1.281,5.8,5.8,0,0,1,1.281,3.968,5.78,5.78,0,0,1-3.968-1.281Zm6.364-2.688a5.805,5.805,0,0,1,3.9-1.281h.07A5.759,5.759,0,0,1,45.1,27.56a5.788,5.788,0,0,1-3.9,1.281h-.07A5.8,5.8,0,0,1,42.414,24.872ZM45.1,19.153a5.8,5.8,0,0,1-3.9,1.281h-.071a5.759,5.759,0,0,1,1.281-3.968,5.79,5.79,0,0,1,3.9-1.281h.07A5.763,5.763,0,0,1,45.1,19.153Z" transform="translate(-33.655 -5.113)" fill="#212020"/>
    </svg>
</template>

<script>
export default {
  name: "Cultivar"
}
</script>
<template>
  <v-app id="inspire" style="background-color: #F8F8F8">
    <keep-alive>
      <password-change-dialog :user="this.user" :dialog.sync="showPasswordChangeDialog"
        @closeDialog="closePasswordChangeDialog" />
    </keep-alive>

    <nav-bar :authority="authority" :permissions="permissions" :drawer.sync="drawer" :initials="nameInitials"
      :username="user.name"></nav-bar>
    <v-app-bar app color="#E21D25" elevate-on-scroll>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" class="white--text" @click="drawer = !drawer" />
      <v-container class="px-8">
        <v-row align="center">
          <v-col class="d-flex justify-start" v-if="authority.slug === 'producer'">
          </v-col>
          <v-col class="d-flex justify-start" v-else>
            <span
              v-if="authority.name === 'Super Administrador' || authority.name === 'Assessor de Sucesso' || indexPanel.includes(true)"
              @click="menuIndexes" class="text-menu white--text pr-10"
              :class="activeMenuIndexes ? 'font-weight-bold' : ''" style="cursor: pointer;">Painel de Índices</span>&nbsp;

            <span
              v-if="authority.name === 'Super Administrador' || authority.name === 'Assessor de Sucesso' || permissions.access_cultivate"
              @click="menuCultivars" class="white--text text-menu font-weight-regular"
              :class="activeMenuCultivars ? 'font-weight-bold' : ''" style="cursor: pointer;">Cultivares</span>
          </v-col>

          <v-col cols="9" class="d-flex justify-end">
            <avatar :username="user.name" :initials="nameInitials">
              <v-list>
                <v-list-item link @click="showPasswordChangeDialog = !showPasswordChangeDialog">
                  <v-list-item-icon>
                    <v-icon>mdi-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span class="font-weight-medium">Alterar Senha</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="logout()">
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span class="font-weight-medium">Sair</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container class="px-6">
        <router-view v-if="charged"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./NavBar";
import Avatar from "@/components/navbar/Avatar";
import LoginService from "@/domain/login/login.service";
import Authorities from "@/domain/authorities/authorities";
import Producers from "@/domain/producers/producers";
import PasswordChangeDialog from "@/components/dialogs/login/PasswordChangeDialog";
import { saveItem } from "@/services/local-storage.service";

import eventBus from "@/event-bus.js";

export default {
  components: {
    NavBar,
    Avatar,
    PasswordChangeDialog,
  },
  data() {
    return {
      drawer: true,
      showPasswordChangeDialog: false,
      user: {
        id: 0,
        name: "",
        password_reset: false,
        authority_id: 0,
      },
      authority: {},
      permissions: {},
      producer: {},
      activeMenuIndexes: true,
      activeMenuCultivars: false,
      charged: false,
      indexPanel: [],
    }
  },
  computed: {
    nameInitials: function () {
      return this.user.name
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    },
  },
  beforeMount() {
    this.setLoggedUser();
  },
  methods: {
    setLoggedUser() {
      const loader = this.$loading.show();
      LoginService.getUserLogged()
        .then((data) => {
          this.user = data;

          Authorities.get(data.authority_id).then((result) => {
            this.authority = result.data;
            saveItem('authoritySlug', result.data.slug);
            if (result.data.slug === 'producer') {
              Producers.getProducerByUserId(this.user.id).then((result) => {
                this.producer = result.data;
                saveItem('producerId', result.data.id);
              });
            }

            if (result.data.slug === 'success-advisor') {
              saveItem('successAdvisorId', this.user.id);
            }

            LoginService.getLoggedUserPermissions().then((result) => {
              this.permissions = result.data;

              this.indexPanel = [
                this.permissions.access_dashboards,
                this.permissions.access_ranking,
                this.permissions.access_records,
                this.permissions.access_as,
                this.permissions.access_technicians,
                this.permissions.access_producers,
                this.permissions.access_internal_users,
                this.permissions.access_indexes,
                this.permissions.access_cultures,
                this.permissions.access_authorities
              ];
              saveItem('permissions', JSON.stringify(this.permissions));
            }).then(() => {
              this.charged = true
            })
          })

          if (this.user.password_reset === true) {
            this.changePassword();
          }
        })
        .catch(() => {
          location.reload();
        })
        .finally(() => {
          loader.hide()
        })
    },
    saveAuthorityData() {
    },
    changePassword() {
      this.showPasswordChangeDialog = true;
    },
    closePasswordChangeDialog() {
      this.showPasswordChangeDialog = false;
    },
    logout() {
      const loader = this.$loading.show();
      LoginService
        .logout()
        .then(() => {
          location.reload();
          loader.hide();
        })
        .catch(() => {
          location.reload();
          loader.hide();
        });
    },
    menuIndexes() {
      this.activeMenuIndexes = true;
      this.activeMenuCultivars = false;
      this.$router.push({ name: "dashboard" });
      eventBus.showPanelIndexes(true);
      this.drawer = true;
    },
    menuCultivars() {
      this.activeMenuCultivars = true;
      this.activeMenuIndexes = false;
      this.$router.push({ name: "productivity" });
      eventBus.showPanelIndexes(false);
      this.drawer = true
    },
  }
}
</script>

<style scoped>
.text-menu {
  font-size: 16px;
}

.active-menu-item {
  font-weight: bold;
}
</style>
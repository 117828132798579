<template>
  <v-list-item :disabled="!link" :to="link" @click.native="callAction" link>
    <v-list-item-icon>
      <v-icon v-if="icon">{{ icon }}</v-icon>
      <slot v-else name="icon"></slot>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <slot></slot>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuItem",
  props: [
    'link',
    'icon',
  ],
  methods: {
    callAction() {
      let previousRoute = localStorage.getItem('previousRoute');

      if (previousRoute !== null && this.link === previousRoute) {
        this.$router.go(0);
      }

      previousRoute = localStorage.setItem('previousRoute', this.link);
    }
  }
}
</script>

<style scoped>
  .v-list .v-list-item--active {
    color: #E21D25;
  }
</style>
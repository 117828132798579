<template>
  <div>
    <v-list v-if="authority === 'Produtor'" nav flat>
      <menu-item link="/dashboard">
        <template slot="icon">
          <panel />
        </template>
        Painel IQL
      </menu-item>

      <menu-item link="/cultivar-productivity">
        <template slot="icon">
          <cultivar />
        </template>
        Cultivares
      </menu-item>

      <menu-item link="/plot-records">
        <template slot="icon">
          <register />
        </template>
        Lotes
      </menu-item>

      <menu-item link="/profile">
        <template slot="icon">
          <profile />
        </template>
        Meu Perfil
      </menu-item>

    </v-list>

    <v-list v-if="authority === 'Assessor de Sucesso'" nav flat>
      <menu-item v-if="showMenuItem" link="/dashboard">
        <template slot="icon">
          <panel />
        </template>
        Painel IQL
      </menu-item>

      <menu-group v-if="showMenuItem" title="Ranking" icon="ranking">
        <template slot="icon">
          <ranking />
        </template>
        <menu-item v-if="showMenuItem" link="/ranking">Ranking IQL</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/management">Ranking Manejo</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/fertility">Ranking Fertilidade</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/financial">Ranking Financeiro</menu-item>
      </menu-group>

      <menu-group v-if="showMenuItem" title="Registros" icon="register">
        <template slot="icon">
          <register />
        </template>
        <menu-item v-if="showMenuItem" link="/management-records">Registro de Manejo</menu-item>
        <menu-item v-if="showMenuItem" link="/fertility-records">Registro de Fertilidade</menu-item>
        <menu-item v-if="showMenuItem" link="/financial-records">Registro Financeiro</menu-item>
      </menu-group>

      <menu-group v-if="showMenuItem" title="Usuários do Sistema" icon="mdi-account-outline">
        <template slot="icon">
          <user />
        </template>
        <menu-item link="/producers">Produtores</menu-item>
        <menu-item class="ml-2" link="/farms">&nbsp;Fazendas</menu-item>
      </menu-group>

      <menu-group v-if="!showMenuItem" title="Registros" icon="register">
        <template slot="icon">
          <register />
        </template>
        <menu-item link="/plot-records">Registro de Lotes</menu-item>        
        <menu-item link="/productivity-ranking">Ranking de Produtividade</menu-item>
      </menu-group>

      <menu-group v-if="!showMenuItem" title="Cultivares">
        <template slot="icon">
          <cultivar />
        </template>
        <menu-item link="/cultivar-productivity">Painel Produtividade</menu-item>
      </menu-group>
    </v-list>

    <v-list v-else nav flat>
      <menu-item v-if="showMenuItem && (authority === 'Super Administrador' || permissions.access_dashboards)"
        link="/dashboard">
        <template slot="icon">
          <panel />
        </template>
        Painel
      </menu-item>    

      <menu-group v-if="showMenuItem && authority !== 'Produtor'" title="Ranking" icon="ranking">
        <template slot="icon">
          <ranking />
        </template>
        <menu-item v-if="showMenuItem" link="/ranking">Ranking IQL</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/management">Ranking Manejo</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/fertility">Ranking Fertilidade</menu-item>
        <menu-item v-if="showMenuItem" link="/rankings/financial">Ranking Financeiro</menu-item>
      </menu-group>

      <menu-group v-if="showMenuItem && (authority === 'Super Administrador' || permissions.access_records)"
        title="Registros" icon="register">
        <template slot="icon">
          <register />
        </template>
        <menu-item v-if="showMenuItem" link="/management-records">Registro de Manejo</menu-item>
        <menu-item v-if="showMenuItem" link="/fertility-records">Registro de Fertilidade</menu-item>
        <menu-item v-if="showMenuItem" link="/financial-records">Registro Financeiro</menu-item>
      </menu-group>

      <menu-group v-if="showMenuItem && (authority === 'Super Administrador' || systemUsers)"
        title="Usuários do Sistema" icon="mdi-account-outline">
        <template slot="icon">
          <user />
        </template>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_as" link="/success-advisors">
          Assessores de Sucesso</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_technicians" link="/technicians">
          Técnicos de Coleta</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_producers" link="/producers">
          Produtores</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_producers" class="ml-2"
          link="/farms">&nbsp;Fazendas</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_internal_users"
          link="/internal-users">Usuários internos</menu-item>
      </menu-group>

      <menu-group v-if="showMenuItem && (authority === 'Super Administrador' || settings)" title="Configurações"
        icon="mdi-cog-outline">
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_indexes" link="/indexes">Índices
          Cadastrados</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_cultures"
          link="/registered-cultures">Culturas Cadastradas</menu-item>
        <menu-item v-if="authority === 'Super Administrador' || permissions.access_authorities" link="/authorities">
          Funções Administrativas</menu-item>
      </menu-group>

      <menu-group v-if="!showMenuItem && (authority === 'Super Administrador' || permissions.access_cultivate)"
        title="Registros" icon="register">
        <template slot="icon">
          <register />
        </template>
        <menu-item link="/plot-records">Registro de Lotes</menu-item>
        <menu-item link="/productivity-ranking">Ranking de Produtividade</menu-item>
      </menu-group>

      <menu-group v-if="!showMenuItem && (authority === 'Super Administrador' || permissions.access_cultivate)"
        title="Cultivares">

        <template slot="icon">
          <cultivar />
        </template>
        <menu-item link="/cultivars">Cultivares Cadastrados</menu-item>
        <menu-item link="/cultivar-brands">Marcas Cadastradas</menu-item>
        <menu-item link="/cultivar-productivity">Painel Produtividade</menu-item>
      </menu-group>
    </v-list>
  </div>
</template>

<script>
import MenuItem from "./MenuItem";
import MenuGroup from "./MenuGroup";

//Menu SVGs
import Panel from "../svgs/Panel";
import Ranking from "../svgs/Ranking";
import Register from "../svgs/Register";
import Cultivar from "../svgs/Cultivar";
import User from "../svgs/User";
import Profile from "../svgs/Profile"

import eventBus from "@/event-bus.js";

export default {
  name: "NavMenu",
  components: {
    MenuItem,
    MenuGroup,
    Panel,
    Ranking,
    Register,
    Cultivar,
    User,
    Profile,
  },
  props: {
    authority: {
      type: String,
      required: true,
    },
    permissions: {
      required: true
    }
  },
  data() {
    return {
      showMenuItem: true
    };
  },
  computed: {
    systemUsers() {
      const permissions = [
        this.permissions.access_as,
        this.permissions.access_technicians,
        this.permissions.access_producers,
        this.permissions.access_internal_users
      ];

      return permissions.includes(true);
    },
    settings() {
      const permissions = [
        this.permissions.access_indexes,
        this.permissions.access_cultures,
        this.permissions.access_authorities
      ];

      return permissions.includes(true);
    }
  },
  created() {
    eventBus.$on('showPanelIndexes', (show) => {
      this.showMenuItem = show;
    });
  },
}
</script>
<template>
<v-list-group no-action :sub-group="subGroup">
  <template v-slot:activator>
    <v-list-item-icon>
      <v-icon v-if="hasMdi">
        {{ icon }}
      </v-icon>
      <slot v-else name="icon"></slot>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </template>
  <slot></slot>
</v-list-group>
</template>

<script>

export default {
  name: "MenuGroup",
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    hasMdi: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
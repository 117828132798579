<template>
  <div>
    <v-menu class="pa-4" rounded="lg" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
            :color="dark
              ? 'white'
              : 'grey lighten-2'"
                  size="45"
                  v-bind="attrs"
                  v-on="on">
          <span class="grey--text text-h6" >
            {{ initials }}
          </span>
        </v-avatar>
      </template>
      <slot></slot>
    </v-menu>
    <span :class="dark
            ? 'pa-4 grey--text text--darken-2 text-menu'
            : 'pa-4 white--text text--darken-2 text-menu font-weight-bold'"
    >
      {{ username }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    initials: {
      type: String,
      required: true,
    },

    username: {
      type: String,
      required: true,
    },

    dark: {
      type: Boolean,
      value: false,
    }
  }
}
</script>

<style scoped>
span {
  font-size: 16px;
  color: #212020;
  opacity: 1;
}

</style>